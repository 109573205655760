import { useStaticQuery, graphql } from 'gatsby'
import FragmentPageIndex from '../../../fragments/allNodePage/page-index'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            index: allNodePage 
            # (
            #     filter: {
            #         field_type: {
            #             eq: "blog"
            #         }
            #     }
            #     sort: {
            #         fields: [created]
            #         order: DESC
            #     }
            # ) 
            {
                edges {
                    node {
                        ...FragmentPageIndex
                    }
                }
            }
        }
    `)

    return data;
}
