import React from "react"
// import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

// s4n
import MenuUtils from "./../Menu/utils"
import Page from "./../../../model/node/page"
import Link from "./../Link"
import kebabCase from "lodash.kebabcase"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
// import Team from './team'

import { globalHistory } from "@reach/router"


const ComponentStateless = (props) => {
    const { intl } = props
    const t = intl.formatMessage;

    const nid = props.nid || null;

    if (null === nid) {
        return null;
    }

    const exclude = props.exclude || [];
    const displaySectionShortDescription = props.displaySectionShortDescriptionForParentNodeId.includes(nid) || false;

    const aNodeMenuItemSubpages = MenuUtils.getSubpagesByNodeId(nid, intl.locale, exclude);
    
    const path = globalHistory.location.pathname;

    return (
        <React.Fragment>
            <div className="_wp-block-soft4net-container-block subpages">
                <div className="wp-block-soft4net-container-block py-0 goto-scroll no-icons">
                    <p>
                        {aNodeMenuItemSubpages.map(({ node: nodeMenuItem }) => {
                            return <strong><a href={`${path}#${kebabCase(nodeMenuItem.title)}`}>{nodeMenuItem.title}</a></strong>
                        })}
                    </p>
                </div>

                {displaySectionShortDescription && aNodeMenuItemSubpages.map(({ node: nodeMenuItem }) => {
                    const nid = MenuUtils.getNodeIdByItem(nodeMenuItem);
                    const nodePage = Page.getItemByNodeId(nid, intl.locale);
                    // const nodeMenuItem = MenuUtils.getItemByNodeId(nid, intl.locale);
                    // const nodeBodyField = useParseInlineImages(nodePage.body ? nodePage.body.value : '', intl) // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute

                    // zespol
                    // if (false && `entity:node/873` === nodeMenuItem.link.uri) {
                    //     return (
                    //         <div className={`wp-block-soft4net-container-block ${kebabCase(nodeMenuItem.title)}`}>
                    //             <h2><Link to={nodeMenuItem.link.url}>{nodeMenuItem.title}</Link></h2>
                    //             <div className="row">
                    //                 <Team />
                    //             </div>
                    //         </div>
                    //     )
                    // }

                    return (
                        <div id={kebabCase(nodeMenuItem.title)} className={`wp-block-soft4net-container-block ${kebabCase(nodeMenuItem.title)}`}>
                            <h2><Link to={nid}></Link></h2>
                            {nodePage && nodePage.field_summary && <p dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>}
                            {/* <p>{nodePage.body}</p> */}
                            {/* {nodeBodyField} */}
                            <p><Link to={nid} className="btn btn-secondary goto-scroll" role="button">{ t({ id: "soft4net_more" }) }</Link></p>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(ComponentStateless)